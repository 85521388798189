
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import "nprogress/nprogress.css";
import "../../styles/global.css";
import { SWRConfig } from "swr";
import { Component as ReactComponent } from "react";
import Router from "next/router";
import NProgress from "nprogress";
import Head from "next/head";
import axios from "axios";
import AuthTokenStore from "@/common/store/AuthTokenStore";
import request from "@/utils/request";
import { createSocketInstance, SocketProvider } from "@/common/context/SocketContext";
import ToastNotification from "@/components/common/ToastNotification";
import isBrowser from "@/utils/isBrowser";
import store from "@/store";
import { Provider } from "react-redux";

// TODO: REMOVE OPTIONS IF UNNEEDED
const fetcher = (url, options) => request.get(url, options).then((res) => res.data);

const CHECK_TOKEN_INTERVAL = process.env.NEXT_PUBLIC_TOKEN_CHECK_INTERVAL_MINUTE * 60 * 1000;

class App extends ReactComponent {
  constructor(props) {
    super(props);
    if (isBrowser()) {
      // eslint-disable-next-line no-underscore-dangle
      request.defaults.headers.common["Accept-Language"] = props.pageProps.__lang;
      AuthTokenStore.initialize();

      this.socket = createSocketInstance();
    }
  }

  componentDidMount() {
    window.OneSignal = window.OneSignal || [];
    window.OneSignal.push(() => {
      window.OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID,
        notifyButton: {
          enable: false,
        },

        allowLocalhostAsSecureOrigin: true,
      });
    });

    NProgress.configure({ showSpinner: false });
    Router.events.on("routeChangeStart", (_, { shallow }) => {
      if (!shallow) NProgress.start();
    });
    Router.events.on("routeChangeComplete", (_, { shallow }) => {
      if (!shallow) NProgress.done();
    });
    Router.events.on("routeChangeError", (_, { shallow }) => {
      if (!shallow) NProgress.done();
    });

    this.tokenCheck();
  }

  componentWillUnmount() {
    window.OneSignal = undefined;
    if (this.tokenCheckTimeout) clearTimeout(this.tokenCheckTimeout);
  }

  tokenCheck = () => {
    const token = AuthTokenStore.get();
    if (token) {
      axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/token`, { headers: { Authorization: `Bearer ${token}` } })
        .catch(() => {
          AuthTokenStore.delete();
          Router.push("/login?tokenExpired=1");
        });
    }

    this.tokenCheckTimeout = setTimeout(this.tokenCheck, CHECK_TOKEN_INTERVAL);
  };

  render() {
    const { Component, pageProps } = this.props;

    return (
      <Provider store={store}>
        <SWRConfig value={{ fetcher, revalidateOnFocus: false }}>
          <SocketProvider socket={this.socket}>
            <ToastNotification />
            <Head>
              <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Head>
            <Component {...pageProps} />
          </SocketProvider>
        </SWRConfig>
      </Provider>
    );
  }
}

const __Page_Next_Translate__ = App;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  