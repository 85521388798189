import { createSlice } from "@reduxjs/toolkit";
import CookieConsentStore from "@/common/store/CookieConsentStore";
import UserTypeStore from "@/common/store/UserTypeStore";

const initialState = {
  isOpenCategoriesSidebar: false,
  cookieConsent: null,
  toastNotifications: [],
  userType: null,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setIsOpenCategoriesSidebar: (state, action) => {
      state.isOpenCategoriesSidebar = action.payload;
    },
    toggleIsOpenCategoriesSidebar: (state) => {
      state.isOpenCategoriesSidebar = !state.isOpenCategoriesSidebar;
    },
    setCookieConsent: (state, action) => {
      state.cookieConsent = action.payload;
      CookieConsentStore.set(action.payload);
    },
    addToastNotification: (state, action) => {
      state.toastNotifications.push(action.payload);
    },
    dismissToastNotification: (state, action) => {
      const index = state.toastNotifications.findIndex((notification) => notification.id === action.payload);
      if (index !== -1) {
        state.toastNotifications.splice(index, 1);
      }
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
      UserTypeStore.set(action.payload);
    },
    setUserTypeValid: (state, action) => {
      const { isConsultant, isCustomer } = action.payload;
      const currentUserType = state.userType ?? UserTypeStore.get();
      state.userType = UserTypeStore.valid(currentUserType, isConsultant, isCustomer);
      UserTypeStore.set(state.userType);
    },
  },
});

export const {
  setIsOpenCategoriesSidebar,
  toggleIsOpenCategoriesSidebar,
  setCookieConsent,
  dismissToastNotification,
  setUserType,
  setUserTypeValid,
} = commonSlice.actions;

export const addToastNotification = (notification) => (dispatch) => {
  dispatch(commonSlice.actions.addToastNotification(notification));

  setTimeout(() => {
    dispatch(dismissToastNotification(notification.id));
  }, 5000);
};

export default commonSlice.reducer;
