import useSWR from "swr";
import { useEffect, useMemo } from "react";
import Router from "next/router";
import AuthTokenStore from "@/common/store/AuthTokenStore";
import { useDispatch } from "react-redux";
import { setUserTypeValid } from "@/store/commonSlice";

export default function useUser(redirectTo = false, redirectIfFound = false) {
  const {
    data: user,
    mutate,
    error,
  } = useSWR("/me", { shouldRetryOnError: false, dedupingInterval: 2147483646 /* 32 bit max int */ });
  const isAuthenticated = useMemo(() => Boolean(user && !error), [user, error]);
  const loading = useMemo(() => !user && !error, [user, error]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error?.response?.status === 401) {
      AuthTokenStore.delete();
    }
  }, [error?.response?.status]);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (loading || !redirectTo) return;

    if (
      // If redirectTo is set, redirect if the user was not found.
      (!redirectIfFound && !user) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && isAuthenticated)
    ) {
      Router.push(redirectTo);
    }
  }, [redirectIfFound, redirectTo, user, loading, isAuthenticated]);

  useEffect(() => {
    if (user?.isConsultant || user?.isCustomer) {
      dispatch(setUserTypeValid({ isConsultant: user.isConsultant, isCustomer: user.isCustomer }));
    }
  }, [dispatch, user?.isConsultant, user?.isCustomer]);

  return { loading, user: user || undefined, mutate, isAuthenticated };
}
