import { createSelector, createSlice } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { mutate } from "swr";

const MESSAGES_PER_PAGE = 30;

const initialState = {
  open: false,
  isMobile: true,
  currentReceiverId: null,
  conversations: [],
  hasNotBalance: false,
  hasSession: false,
  connectedAt: null,
  messages: [],
  isMessagesLoading: false,
  isStarted: false,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChatOpen: (state, action) => {
      state.open = action.payload;
      if (action.payload === false) {
        request.get("/connection/stop").then();
      }
    },
    setChatReceiverId: (state, action) => {
      state.currentReceiverId = action.payload;
      state.messages = [];
    },
    setChatConversations: (state, action) => {
      state.conversations = action.payload;
      if (state.currentReceiverId === null && action.payload.length) {
        state.currentReceiverId = action.payload[0].id;
      }
    },
    setChatNoBalance: (state, action) => {
      state.hasNotBalance = action.payload;
    },
    closeChatSession: (state) => {
      state.hasSession = false;
      state.connectedAt = null;
      state.isStarted = false;
    },
    handleChatCheckSuccess: (state, action) => {
      const data = action.payload;
      state.connectedAt = data.connectedAt ?? null;
      state.hasSession = data.success;
      state.isStarted = data.status === "connected";

      if (data.isConsultant) {
        state.hasNotBalance = false;
      }
    },
    updateChatConversation: (state, action) => {
      const { conversationId, key, value } = action.payload;

      const index = state.conversations.findIndex((c) => c.id === conversationId);
      if (index !== -1) {
        state.conversations[index][key] = value;
      }
    },
    addChatConversation: (state, action) => {
      state.conversations.push(action.payload);
    },
    setChatIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setChatMessagesLoading: (state, action) => {
      state.isMessagesLoading = action.payload;
    },
    addChatMessages: (state, action) => {
      state.messages[action.payload.page] = action.payload.data;
      state.isMessagesLoading = false;
    },
    addChatMessage: (state, action) => {
      if (state.messages.length > 0) {
        state.messages[0].messages.push(action.payload);
      }
    },
  },
});

export const {
  setChatOpen,
  setChatReceiverId,
  handleChatCheckSuccess,
  setChatNoBalance,
  updateChatConversation,
  addChatConversation,
  setChatIsMobile,
  addChatMessage,
} = chatSlice.actions;

export const closeChatSession = () => async (dispatch) => {
  dispatch(chatSlice.actions.closeChatSession());

  await mutate("/me", (u) => ({ ...u, balanceAmount: 0 }));
};

export const fetchChatConversations = (dispatch) => {
  request
    .get("/chats")
    .then(({ data }) => dispatch(chatSlice.actions.setChatConversations(data)))
    .catch();
};

export const fetchChatCheck = (dispatch, getState) => {
  const { currentReceiverId } = getState().chat;

  request
    .post(`/chat-check/${currentReceiverId}`)
    .then(({ data }) => dispatch(handleChatCheckSuccess(data)))
    .catch((err) => {
      dispatch(closeChatSession());

      // 406 => has no balance error code
      dispatch(setChatNoBalance(err.response?.status === 406));
    });
};

/*
{
  "me":{"consultant":false,"picture":null,"name":"burhan","personalInfo":null},
  "receiver":{"consultant":true,"picture":"https:\/\/vialantis.fra1.cdn.digitaloceanspaces.com\/cdn\/consultants\/20220117044714205c13b8-9ce9-484a-9b4f-f50fcf69e0bc.png","name":"Burhan K.","personalInfo":null},
  "messages":[{"id":"76922c41-7ea9-11ec-91d2-02420aff0005","messageFromId":"f2be3aa8-7742-11ec-82b0-02420aff003b","messageToId":"7de9365a-3bd8-11ec-82b0-02420aff003b","type":null,"message":"dsf","isReaded":false,"createdAt":"2022-01-26T14:11:24+01:00","me":false}]
}
 */
export const fetchChatMessages =
  (loadMore = false) =>
  (dispatch, getState) => {
    const { currentReceiverId, messages, isMessagesLoading } = getState().chat;

    if (
      isMessagesLoading ||
      !currentReceiverId ||
      (messages.length > 0 && messages[messages.length - 1] < MESSAGES_PER_PAGE)
    ) {
      return;
    }

    dispatch(chatSlice.actions.setChatMessagesLoading(true));

    const page = loadMore ? messages.length : 0;
    request
      .get(`/chat/${currentReceiverId}?page=${page + 1}`)
      .then(({ data }) => dispatch(chatSlice.actions.addChatMessages({ data, page })))
      .catch(() => dispatch(chatSlice.actions.setChatMessagesLoading(false)));
  };

export const selectChatCurrentReceiver = createSelector(
  (state) => state.chat.conversations,
  (state) => state.chat.currentReceiverId,
  (conversations, currentReceiverId) => conversations.find((conversation) => conversation.id === currentReceiverId)
);

export default chatSlice.reducer;
